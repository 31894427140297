import { Box } from "@chakra-ui/react";
import useAuth from "@raiden/library-ui/hooks/useAuth";
import Seo from "../components/Seo";
import { useAutoLayout } from "../hooks/useAutoLayout";

function Page() {
  const { userCustomer } = useAuth();

  const Layout = useAutoLayout({ user: userCustomer });

  return (
    <>
      <Seo title="404" />

      <Box flexGrow={1}>
        <Layout.error404Component />
      </Box>
    </>
  );
}

export default Page;
